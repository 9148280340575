import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import { Accent, ButtonLink, SectionContent } from '../../common/styles'
import colors from '../../constants/colors'
import video from '../../videos/video_margot_maquille.mp4'

import {
  ButtonContainer,
  HeroDescription,
  HeroPhrase,
  HeroSectionContainer
} from './HeroSection.styles'

const query = graphql`
  query {
    strapiHome {
      hero {
        data {
          hero
        }
      }
    }
  }
`

const HeroSection = () => {
  const {
    strapiHome: { hero }
  } = useStaticQuery(query)

  return (
    <HeroSectionContainer>
      <SectionContent>
        <video
          src={video}
          autoPlay
          loop
          playsInline
          muted
          style={{
            width: '100%',
            maxHeight: 'calc(100vh - 250px)',
            objectFit: 'cover',
            borderRadius: 20
          }}
        />
        <HeroPhrase>
          Margot Emery, <Accent>maquilleuse professionnelle</Accent> à Nantes.
        </HeroPhrase>
        <HeroDescription>
          <ReactMarkdown>{hero?.data?.hero!}</ReactMarkdown>
        </HeroDescription>
        <ButtonContainer>
          <ButtonLink color={colors.pink} to='/prestations/' large>
            Voir toutes mes prestations
          </ButtonLink>
          <ButtonLink color={colors.brown} to='/boutique/' large>
            Cartes cadeaux
          </ButtonLink>
        </ButtonContainer>
      </SectionContent>
    </HeroSectionContainer>
  )
}

export default HeroSection
