import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import { ButtonLink } from '../../common/styles'
import Chip from '../../components/Chip'

import {
  Container,
  ChipsList,
  ButtonWrapper,
  FieldsSectionContainer
} from './FieldsSection.styles'

const query = graphql`
  query {
    allStrapiChip {
      edges {
        node {
          title
          index
          chipImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 200, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
`

const FieldsSection = () => {
  const {
    allStrapiChip: { edges: chips }
  } = useStaticQuery(query)

  return (
    <FieldsSectionContainer largeVerticalMargin>
      <Container>
        <ChipsList>
          {chips
            .sort((a, b) => a.node.index - b.node.index)
            .map(({ node: { title, chipImage } }) => {
              const image = getImage(chipImage.localFile)
              return <Chip image={image} label={title} key={title} />
            })}
        </ChipsList>
        <ButtonWrapper>
          <ButtonLink to='/contact/' large>
            Demander un devis
          </ButtonLink>
        </ButtonWrapper>
      </Container>
    </FieldsSectionContainer>
  )
}

export default FieldsSection
