import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'

import {
  Accent,
  CardSection,
  ContentContainer,
  Title,
  HalfWidthImageWrapper,
  HalfWidthImage
} from '../../common/styles'

import {
  BioSectionContent,
  PortraitWrapper,
  Portrait,
  TitleWrapper,
  BioDescription
} from './BioSection.styles'

const query = graphql`
  query {
    strapiHome {
      about {
        data {
          about
        }
      }
      bioImage {
        localFile {
          childImageSharp {
            gatsbyImageData(height: 400, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`

const BioSection = () => {
  const {
    strapiHome: { about, bioImage }
  } = useStaticQuery(query)
  const image = getImage(bioImage.localFile)

  return (
    <CardSection>
      <HalfWidthImageWrapper>
        <HalfWidthImage image={image} alt='Margot Maquille' />
      </HalfWidthImageWrapper>
      <BioSectionContent>
        <ContentContainer>
          <PortraitWrapper>
            <Portrait image={image} alt='Margot Maquille' />
          </PortraitWrapper>
          <TitleWrapper>
            <Title>
              À propos de <Accent>Margot</Accent>, maquilleuse professionnelle à
              Nantes
            </Title>
          </TitleWrapper>
          <BioDescription>
            <ReactMarkdown>{about?.data?.about!}</ReactMarkdown>
          </BioDescription>
        </ContentContainer>
      </BioSectionContent>
    </CardSection>
  )
}

export default BioSection
