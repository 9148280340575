import styled from 'styled-components'

import { Card, SectionContent } from '../../common/styles'
import breakpoint from '../../constants/breakpoint'
import spacing from '../../constants/spacing'

export const BackgroundSection = styled(SectionContent)<{ imageUrl: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: ${spacing.MAIN_PADDING};

  @media ${breakpoint.LAPTOP} {
    flex-direction: row;
    padding-bottom: ${spacing.MAIN_PADDING};
    background-image: url(${({ imageUrl }) => imageUrl || ''});
    background-position: bottom right;
    background-repeat: no-repeat;
    align-items: flex-start;
  }
`

export const Container = styled(Card)`
  border: none;
  padding: 35px;

  @media ${breakpoint.LAPTOP} {
    &:first-child {
      margin-right: ${spacing.MAIN_PADDING};
    }
  }

  @media ${breakpoint.TABLET} {
    max-width: ${spacing.MAX_CONTENT_HALF_WIDTH};
  }
`

export const Text = styled.p`
  font-size: 14px;
  font-family: Poppins;
  line-height: 28px;
`

export const Header = styled.div`
  text-align: center;
  font-family: DM Serif Display;
  font-size: 21px;
  font-weight: 400;
  padding-bottom: 14px;
  line-height: 28px;
`
