import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import { Accent } from '../../common/styles'

import {
  BackgroundSection,
  Container,
  Header
} from './ExperienceAndBrandsSection.styles'

const query = graphql`
  query {
    strapiHome {
      experienceImage {
        formats {
          small {
            url
          }
        }
      }
    }
  }
`

const ExperienceAndBrandsSection = ({ experience, brands }) => {
  const {
    strapiHome: {
      experienceImage: {
        formats: {
          small: { url }
        }
      }
    }
  } = useStaticQuery(query)

  return (
    <BackgroundSection imageUrl={url}>
      <Container bordered>
        <Header>
          Ils sont passés sous <Accent>mes pinceaux 🌟</Accent>
        </Header>
        <ReactMarkdown>{experience}</ReactMarkdown>
      </Container>
      <Container bordered>
        <Header>
          <Accent>Les marques</Accent> qui m&apos;accompagnent 💄
        </Header>
        <ReactMarkdown>{brands}</ReactMarkdown>
      </Container>
    </BackgroundSection>
  )
}

export default ExperienceAndBrandsSection
