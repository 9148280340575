import { graphql, PageProps } from 'gatsby'

import Layout from '../layouts'
import BioSection from '../sections/BioSection'
import ExperienceAndBrandsSection from '../sections/ExperienceAndBrandsSection'
import FieldsSection from '../sections/FieldsSection'
import HeroSection from '../sections/HeroSection'
import ServicesSection from '../sections/ServicesSection'
import TestimonialsSection from '../sections/TestimonialsSection'

const Index = ({
  data: {
    strapiHome,
    allStrapiTestimonial: { edges: testimonials }
  }
}: PageProps<Queries.IndexQuery>) => {
  return (
    <Layout
      showCTA
      seo={{
        title: strapiHome?.seo?.title,
        description: strapiHome?.seo?.description
      }}
    >
      <HeroSection />
      <ServicesSection />
      <BioSection />
      <TestimonialsSection testimonials={testimonials} />
      <FieldsSection />
      <ExperienceAndBrandsSection
        experience={strapiHome?.experience?.data?.experience}
        brands={strapiHome?.brands?.data?.brands}
      />
    </Layout>
  )
}

export default Index

export const query = graphql`
  query Index {
    strapiHome {
      brands {
        data {
          brands
        }
      }
      experience {
        data {
          experience
        }
      }
      seo {
        description
        title
      }
    }
    allStrapiTestimonial {
      edges {
        node {
          id
          author
          quote {
            data {
              quote
            }
          }
          url
        }
      }
    }
  }
`
