import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import breakpoint from '../../constants/breakpoint'
import colors from '../../constants/colors'
import shadow from '../../constants/shadow'

export const ChipContainer = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 21px 21px 21px;
  max-width: 150px;
`

export const ChipTitle = styled.div`
  font-family: DM Serif Display;
  font-size: 21px;
  font-weight: 400;
  padding-bottom: 14px;
  line-height: 28px;
  text-align: center;
`

export const RoundImage = styled(GatsbyImage).attrs({
  imgStyle: {
    borderRadius: '100%'
  }
})`
  [data-placeholder-image] {
    border-radius: 100%;
  }
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin-bottom: 21px;
  box-shadow: ${shadow.MAIN_BOX_SHADOW};
  border: solid 1px ${colors.white};
  object-fit: cover;
  object-position: center center;
  transition: transform 200ms;

  @media ${breakpoint.LAPTOP} {
    width: 200px;
    height: 200px;
  }
`
