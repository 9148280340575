import { ChipContainer, RoundImage, ChipTitle } from './Chip.styles'

const Chip = ({ image, label }) => (
  <ChipContainer>
    <RoundImage
      image={image}
      alt={`Image d'illustation pour le thème: ${label}`}
    />
    <ChipTitle>{label}</ChipTitle>
  </ChipContainer>
)

export default Chip
