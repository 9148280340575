import styled from 'styled-components'

import { ButtonWrapper, Section } from '../../common/styles'
import breakpoint from '../../constants/breakpoint'
import spacing from '../../constants/spacing'

export const HeroSectionContainer = styled(Section)`
  flex-direction: column;

  @media ${breakpoint.TABLET} {
    flex-direction: row;
  }
`

export const HeroPhrase = styled.h1`
  text-align: center;
  font-family: DM Serif Display;
  font-size: 28px;
  font-weight: 400;
  line-height: 42px;
  padding: 0 0 24px;
  margin-top: ${spacing.MEDIUM_PADDING};

  @media ${breakpoint.TABLET} {
    font-size: 35px;
    line-height: 48px;
    margin-top: 42px;
  }
`

export const HeroDescription = styled.div`
  border-radius: 8px;
  z-index: 1;
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 14px;
`

export const ButtonContainer = styled(ButtonWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: ${spacing.MAIN_PADDING};

  & > :not(:last-child) {
    margin-right: 0;
    margin-bottom: ${spacing.MEDIUM_PADDING};
  }

  @media ${breakpoint.TABLET} {
    flex-direction: row;

    & > :not(:last-child) {
      margin-right: ${spacing.MEDIUM_PADDING};
      margin-bottom: 0;
    }
  }
`
