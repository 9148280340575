import styled from 'styled-components'

import { Section, SectionContent } from '../../common/styles'
import breakpoint from '../../constants/breakpoint'

export const FieldsSectionContainer = styled(Section)`
  margin-bottom: 0;
`

export const Container = styled(SectionContent)`
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
`

export const ChipsList = styled.ul`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`

export const ButtonWrapper = styled.div`
  display: none;
  justify-content: center;
  margin-bottom: 14px;

  @media ${breakpoint.TABLET} {
    display: flex;
  }
`
