import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { Card, Section, SectionContent } from '../../common/styles'
import breakpoint from '../../constants/breakpoint'
import spacing from '../../constants/spacing'

export const ServicesSectionContainer = styled(Section)`
  margin-top: 0;
  flex-direction: column;

  @media ${breakpoint.TABLET} {
    margin-top: ${spacing.MEDIUM_PADDING};
  }
`

export const DecorationImage = styled(GatsbyImage)`
  opacity: 0.7;
  filter: sepia(0.1);
  width: 150px;
  will-change: transform;

  @media ${breakpoint.TABLET} {
    transform: rotate(90deg);
  }
`

export const DecorationImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ServicesSectionContent = styled(SectionContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${breakpoint.TABLET} {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
`

export const ServiceCard = styled(Card)<{ color?: string }>`
  max-width: 450px;
  border: none;
  padding: 35px;
  &:last-child {
    margin-bottom: 0;
  }

  @media ${breakpoint.TABLET} {
    &:first-child {
      margin-right: ${spacing.MAIN_PADDING};
    }
  }
`
