import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { SectionContent } from '../../common/styles'
import breakpoint from '../../constants/breakpoint'
import colors from '../../constants/colors'

export const PortraitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media ${breakpoint.TABLET} {
    display: none;
  }
`

export const Portrait = styled(GatsbyImage).attrs({
  imgStyle: {
    borderRadius: '100%'
  }
})`
  [data-placeholder-image] {
    border-radius: 100%;
  }
  margin: 0 0 21px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center center;
  border-radius: 100%;
`
export const BioSectionContent = styled(SectionContent)`
  color: ${colors.darkRed};
  line-height: 28px;
`

export const BioDescription = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;

  p {
    margin-bottom: 14px;
  }
`

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media ${breakpoint.TABLET} {
    justify-content: flex-start;
  }
`
