import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import {
  Description,
  Title,
  ButtonWrapper,
  ButtonLink,
  Accent
} from '../../common/styles'
import colors from '../../constants/colors'

import {
  ServicesSectionContainer,
  DecorationImage,
  DecorationImageContainer,
  ServicesSectionContent,
  ServiceCard
} from './ServicesSection.styles'

const query = graphql`
  query MyQuery {
    strapiHome {
      servicesImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              width: 400
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`

const ServicesSection = () => {
  const {
    strapiHome: { servicesImage }
  } = useStaticQuery(query)

  return (
    <ServicesSectionContainer largeVerticalMargin>
      <ServicesSectionContent>
        <ServiceCard bordered>
          <Title centered>
            Prestations de maquillage professionnel pour les{' '}
            <Accent>particuliers</Accent>
          </Title>
          <Description>
            <p>
              Maquillage pour <strong>mariage</strong>, cours d&apos;
              <strong>auto-maquillage</strong>, atelier de mise en beauté du
              jour et sophistiquée...
            </p>
            <p>
              J’ai la chance de travailler auprès de partenaires comme{' '}
              <strong>Blissim</strong> (ex-Birchbox) pour des tutos et ateliers.
            </p>
            <p>
              Je vous sublime grâce aux meilleures techniques et suis prête à
              partager mon savoir-faire.
            </p>
          </Description>
          <ButtonWrapper>
            <ButtonLink to='/prestations/' large>
              Découvrir
            </ButtonLink>
          </ButtonWrapper>
        </ServiceCard>
        <ServiceCard color={colors.brown} bordered>
          <Title centered>
            Prestations de maquillage professionnel pour les{' '}
            <Accent color={colors.brown}>professionnels</Accent>
          </Title>
          <Description>
            <p>
              Photo mode, TV, cinéma, publicité, institutionnel, événementiel...
            </p>
            <p>
              Je collabore avec des enseignes prestigieuses comme{' '}
              <strong>Wagram</strong>, <strong>Because Music</strong> et{' '}
              <strong>Konbini</strong>.
            </p>
            <p>
              Découvrons ensemble comment un maquillage professionnel peut
              bénéficier à votre image de marque !
            </p>
          </Description>
          <ButtonWrapper>
            <ButtonLink
              to='/prestations/professionnels/'
              color={colors.brown}
              large
            >
              Voir mes expériences
            </ButtonLink>
          </ButtonWrapper>
        </ServiceCard>
      </ServicesSectionContent>
      <DecorationImageContainer>
        <DecorationImage
          image={getImage(servicesImage.localFile)}
          alt={`Image d'illustration pour la section "Services"`}
        />
      </DecorationImageContainer>
    </ServicesSectionContainer>
  )
}

export default ServicesSection
