import styled, { css } from 'styled-components'

import { Title } from '../../common/styles'
import breakpoint from '../../constants/breakpoint'
import colors from '../../constants/colors'
import shadow from '../../constants/shadow'

export const SectionTitle = styled(Title)`
  margin-bottom: 21px;
`

export const Container = styled.div`
  margin: 70px 0 70px;

  @media ${breakpoint.LAPTOP} {
    padding: 0 42px;
  }
`

export const TestimonialContainer = styled.div`
  padding: 0 14px 14px;
`

export const TestimonialCard = styled.div`
  height: 550px;
  background: #ffeeee;
  color: #334d59;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 21px;
  text-align: center;
  box-shadow: ${shadow.LIGHT_BOX_SHADOW};
  border: solid 1px ${colors.darkRed};

  @media (min-width: 1024px) {
    height: 450px;
  }
`

export const TestimonialQuote = styled.blockquote`
  flex: 1;
  font-style: italic;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #334d59;
`

export const TestimonialAuthor = styled.cite`
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
`

export const StarsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

export const PostedOn = styled.div`
  font-size: 12px;
  margin-top: 20px;
  text-align: right;
`

export const CustomArrow = styled.button<{ left?: boolean; right?: boolean }>`
  ${({ left }) =>
    left &&
    css`
      left: -28px;
    `}
  ${({ right }) =>
    right &&
    css`
      right: -28px;
    `}
  position: absolute;
  top: 200px;
  height: 28px;
  width: 28px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${shadow.LIGHT_BOX_SHADOW};
  border-radius: 50%;
  border: none;
  outline: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: ${shadow.MAIN_BOX_SHADOW};
  }
`

export const SampleNextArrow = ({ onClick }: { onClick: () => void }) => (
  <CustomArrow onClick={onClick} right>
    →
  </CustomArrow>
)

export const SamplePrevArrow = ({ onClick }: { onClick: () => void }) => (
  <CustomArrow onClick={onClick} left>
    ←
  </CustomArrow>
)
