import Slider from 'react-slick'

import { Accent } from '../../common/styles'
import MarkdownWrapper from '../../components/MarkdownWrapper'

import {
  Container,
  PostedOn,
  SampleNextArrow,
  SamplePrevArrow,
  SectionTitle,
  StarsContainer,
  TestimonialAuthor,
  TestimonialCard,
  TestimonialContainer,
  TestimonialQuote
} from './TestimonialsSection.styles'

type Props = {
  testimonials: Pick<
    Queries.IndexQuery,
    'allStrapiTestimonial'
  >['allStrapiTestimonial']['edges']
}

const HeroSection = ({ testimonials }: Props) => {
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 5000,
    initialSlide: 0,
    adaptiveHeight: true,
    // @ts-ignore
    nextArrow: <SampleNextArrow />,
    // @ts-ignore
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          arrows: false
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          arrows: false
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false
        }
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  }

  return (
    <Container>
      <SectionTitle centered>
        Quelques <Accent>compliments</Accent> de mes client.e.s
      </SectionTitle>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <TestimonialContainer key={index}>
            <TestimonialCard>
              <TestimonialAuthor>{testimonial.node.author}</TestimonialAuthor>
              <StarsContainer>⭐️⭐️⭐️⭐️⭐️</StarsContainer>
              <TestimonialQuote>
                <MarkdownWrapper>
                  {testimonial.node.quote?.data!.quote!}
                </MarkdownWrapper>
              </TestimonialQuote>
              <PostedOn>
                <a
                  href={testimonial.node.url!}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Source: Google
                </a>
              </PostedOn>
            </TestimonialCard>
          </TestimonialContainer>
        ))}
      </Slider>
    </Container>
  )
}

export default HeroSection
